import 'reflect-metadata';
import {TranslateService} from '@ngx-translate/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {EcaseHttpService} from './services/ecase-http.service';
import 'moment-timezone';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs/internal/Observable';

import moment from 'moment';
import {switchMapTo} from "rxjs/operators";


export class ECaseUtilsGlobal {
  /* New taxonomy term id   *******************/
  static CHANGE_ELIGIBILITY_CRITERIAS = 1251;
  static STATUS_APPLICATION_IN_PROGRESS = 4;
  static STATUS_AWF_IN_PROGRESS = 18;
  static STATUS_AWF_RETURNED_BY_AGENCY_TO_CANDIDATE = 21;
  static STATUS_APPLICATION_SUBMITTED_TO_AGENCY_BY_CANDIDATE = 8;
  static STATUS_APPLICATION_SUBMITTED_TO_AGENCY_BY_INSTITUTION = 9;
  static STATUS_APPLICATION_RETURNED_TO_CANDIDATE_BY_AGENCY = 10;
  static STATUS_APPLICATION_RETURNED_TO_INSTITUTION_BY_AGENCY = 11;
  static STATUS_APPLICATION_PROJECT_TERMINATED = 29;
  static STATUS_APPLICATION_REVISED = 25100;
  static STATUS_APPLICATION_ASSIGNED = 141;
  static STATUS_APPLICATION_ELIGIBLE = 12;
  static STATUS_APPLICATION_ELIGIBLE_URGENT = 142;
  static STATUS_APPLICATION_NOT_ELIGIBLE = 13;
  static ADMIN_TAXONOMY_DOCUMENT_TYPE = '35';
  static ADMIN_TAXONOMY_SYSTEM_DOCUMENT_TYPE = '66';
  static ADMIN_TAXONOMY_FORMAT_TYPE = '8';
  static STATUS_PROJECT_ARCHIVED = 2;
  static STATUS_APPLICATION_SUBMITTED_AFTER_CORRECTION = 5;
  static STATUS_APPLICATION_RETURNED_BY_INSTITUTION_TO_CANDIDATE = 6;
  static STATUS_APPLICATION_SUBMITTED_BY_CANDIDATE_TO_INSTITUTION_AFTER_CORRECTION = 7;
  static STATUS_APPLICATION_ARCHIVED = 13;
  static STATUS_APPLICATION_NOT_FUNDED = 20;
  static STATUS_APPLICATION_FUNDED = 23;
  static APPLICATION_STATUS_RETURNED_FROM_DIRECTOR_OR_SUPERVISOR = 1327;
  static CLASSIC_GRANTS_PROGRAM_TYPE_CLASSIFICATION = 7601;
  static EXCLUSIVELY_FOR_FUND_MANAGER = 7602;
  static EXCLUSIVELY_FOR_CONFORMITY_PROGRAM_TYPE_CLASSIFICATION = 7603;
  static PROGRAM_RSG_FUNDING_APPLICATION_COMPETITION = 2;
  static STATUS_APPLICATION_WAITING_FOR_PI_APPROVAL = 738;
  static STATUS_APPLICATION_WAITING_FOR_DIRECTOR_APPROVAL = 712;
  static STATUS_APPLICATION_WAITING_FOR_SUP_APPROVAL = 439;
  static APPLICATION_STATUS_PENDING_CO_INSTRUCTOR = 556;
  static STATUS_APPLICATION_RETURNED = 44;
  static STATUS_APPLICATION_APPROVED = 14;
  static STATUS_APPLICATION_MIGRATED_APPROVED = 414;
  static STATUS_APPLICATION_WITHDRAWN = 28;
  static STATUS_APPLICATION_OUT_OF_SCOPE = 140;
  static ORE_POST_APPROVAL_FORM_ID = 55;
  static MIGRATED_STUDIES_FORM_ID = 88;
  static STATUS_APPLICATION_APPROVED_TERM_VALUE = 'ecase.taxonomy.2.14';
  static STATUS_APPLICATION_PENDING_CO_INSTRUCTOR_TERM_VALUE = 'ecase.taxonomy.2.556';
  static STATUS_APPLICATION_WAITING_FOR_SUP_APPROVAL_TERM_VALUE = 'ecase.taxonomy.2.439';
  static STATUS_APPLILCATION_WAITING_FOR_DIRECTOR_DEAN_TERM_VALUE = 'ecase.taxonomy.2.712';
  static PROJECT_TEAM_MEMBER_PI_ROLE_ID = 6;
  static STATUS_APPLICATION_ARCHIVED_TERM_VALUE = 'ecase.taxonomy.2.4';
  static STATUS_APPLICATION_SUBMITTED_TERM_VALUE = 'ecase.taxonomy.2.5';
  static STATUS_APPLICATION_PROGRESS_TERM_VALUE = 'ecase.taxonomy.10.4';
  static STATUS_APPLICATION_PROVISOS_TERM_VALUE = 'ecase.taxonomy.2.44';
  static STATUS_APPLICATION_MIGRATED_APPROVED_TERM_VALUE = 'ecase.taxonomy.2.414';
  static REVIEW_COMMITTEE_STATUS_ENABLED = 1;
  static REVIEW_COMMITTEE_STATUS_DISABLED = 0;
  static PROJECT_TEAM_MEMBER_TERM_PARENT_ID_FOR_PI_ROLE = 31;
  static PROJECT_TEAM_MEMBER_TERM_PARENT_ID_FOR_DELEGATED_ROLE = 37;
  static PROJECT_TEAM_MEMBER_TERM_PARENT_ID_FOR_COLLABORATOR_WITHOUT_USERNAME_ROLE = 35;
  static REVIEW_ETHICS_OFFICER_ROLE = 15;
  static REVIEW_ETHICS_MANAGER_ROLE = 17;
  static REVIEW_ETHICS_CHAIR_ROLE = 18;
  static ROLE_ADMIN_READ_ONLY = 7;
  static ROLE_ADMIN = 2;
  static ROLE_SUPER_USER = 11;
  static ROLE_ADMIN_MASQUERADER_FULL_ACCESS = 15;
  static ROLE_ADMIN_MASQUERADER_READ_ONLY = 16;
  static ROLE_SUPPORT_CENTER = 8 ;

  static USER_PROFILE_TERM_PARENT_COUNTRY_CANADA = 4588;
  static USER_PROFILE_TERM_PARENT_COUNTRY_USA = 4787;
  static USER_PROFILE_TERM_PARENT_COUNTRY_KUWAIT = 4666;
  static ACCESS_TYPE_LEVEL_READ_ONLY = 1272;
  static ACCESS_TYPE_LEVEL_NO_ACCESS = 1273;
  static BEHAVIORAL_FORM_TYPE_ID = 714;
  static CLINICAL_FORM_TYPE_ID = 715;
  static COURSE_STUDY_FORM_TYPE_ID = 713;
  static ACKNOWLEDGEMENT_TYPE_TERM_ID = 38;
  static EMAIL_BODY_MIME_TYPE_DOCX = 'ecase.taxonomy.69.826';
  static OTHER_VALUES: number[] = [9223372036854775807, 9223372036854776000, 999999999];
  static OTHER_CRM_ORGANISATION_ID_STRING_FORM = '999999999';
  static SYSTEM_DOCUMENT_CLASSIFICATION_ATTACHED_TO_FORM_BY_TEAM_MEMBER = 1001;
  static ANGULAR_VERSION = '9';
  static OTHER_VALUE_TERM_ID = 999999999;
  static SALUTATION_TAXONOMY_ID = '19';
  static DOCUMENT_TYPE = '66';
  static GENDER_TAXONOMY_ID = '26';
  static POSITIONS_TAXONOMY_ID = '21';
  static CONTRACT_TYPE_TAXONOMY_ID = '47';
  static COUNTRY_TAXONOMY_ID = '39';
  static APPLICATION_STATUS_TAXONOMY_ID = '10';
  static APPLICATION_PHASE_TAXONOMY_ID = 53;
  static APPLICATION_TEAM_MEMBER_ROLE_TAXONOMY_ID = 12;
  static PAYMENT_IDENTIFIER_TAXONOMY = '93';
  static PAYMENT_TYPE_FORMAT_TAXONOMY ='97';
  static COUNTRY_CALLING_CODES_TAXONOMY_ID = '49';
  static PROVINCE_TAXONOMY_ID = '40';
  static SYNTO_WORKSPACE_TAXONOMY_ID = '3';
  static ORGANIZATION_LEGAL_STATUS_TAXONOMY_ID = 82;
  static STRUCTURE_CLASSIFICATION_TAXONOMY_ID = 17;
  static STRUCTURE_STATUS_TYPE_TAXONOMY_ID = 27;
  static ORGANIZATION_STATUS_WAITING_FOR_APPROVAL = 711;
  static ORGANIZATION_STATUS_APPROVAL = 712;
  static ADMIN_CRM_PERSON_PAGE_FEATURE_ID = 96;
  static GDPR_COMPLIANCE_PRIVACY_NOTICE_TERM_ID = 10201;

  static USER_ACCOUNT_STATUS_WAITING_FOR_APPROVAL = 721;
  static USER_ACCOUNT_STATUS_DEACTIVATED = 724;
  static USER_ACCOUNT_STATUS_ACTIVATED = 725;

  static PROCESS_PHASE_TO_BE_COMPLETED_BY_CANDIDATE = 5530;
  static PROCESS_PHASE_ELIGIBILITY = 5532;
  static PROCESS_PHASE_REVIEW = 5533;
  static PROCESS_PHASE_FINALIZATION = 5534;
  static PROCESS_PHASE_FUNDING_POST_AWARD = 5535;
  static PROCESS_PHASE_ARCHIVED = 5536;
  static SYS_ACTION_ADD_AFFILIATION_TO_PROFILE = 1277;
  static SYS_ACTION_ADD_BANK_ACCOUNT_TO_PROFILE = 1278;

  static APPLICANT_CAMUNDA_TASK_TYPE_CLASSIFICATION = 12001;
  static FUNDING_OFFER_LETTER_SYSTEM_DOCUMENT_TYPE = 7715;
  static NOTIFICATION_SYSTEM_DOCUMENT_TYPE = 7719;
  static ATTACHED_TO_PROJECT_BY_TEAM_MEMBER_DOCUMENT_TYPE = 1188;
  static ATTACHED_TO_PROJECT_BY_TEAM_MEMBER_FOR_FUNDING_DOCUMENT_TYPE = 1191;
  static LOV_FORM_TYPES = '33';
  static FILE_UPLOADER_SUPPORTED_EXTENSIONS = ['.pdf','.doc','.docx','.xls','.xlsx','.gif', '.png', '.jpeg', '.svg',
    '.pptx', '.ppt', '.ppsx', '.mp4', '.mov', '.rtf', '.odp', '.bmp', '.mpp', '.csv', '.odp','.msg'];

  static CPM_FORM_TYPE_ID_GENERAL_PROJECT = 911;
  static CPM_FORM_TYPE_ID_GRANT_PRE_APPLICATION = 912;
  static CPM_FORM_TYPE_ID_GRANT_APPLICATION = 913;
  static CPM_FORM_TYPE_ID_ETHICS_APPLICATION = 914;
  static CPM_FORM_TYPE_ID_ETHICS_REVIEW = 915;
  static CPM_FORM_TYPE_ID_GMS_AWF_APPLICATION = 916;
  static CPM_FORM_TYPE_ID_GMS_PA_REPORT = 917;
  static CPM_FORM_TYPE_ID_GMS_AMENDMENT = 918;

  static PA_REPORT_NATURE_CONTINUOUS = 11300;
  static PA_REPORT_NATURE_SCHEDULED = 11301;
  static PA_REPORT_NATURE_ON_DEMAND = 11302;
  static PA_REPORT_NATURE_AMENDMENT = 11303;

  static PA_REPORT_STATUS_IN_PROGRESS = 11512;
  static PA_REPORT_STATUS_SUBMITTED = 11513;
  static PA_REPORT_STATUS_RETURNED_FOR_CORRECTION = 11514;
  static PA_REPORT_STATUS_APPROVED = 11515;
  static PA_REPORT_STATUS_REJECTED = 11516;

  static PROGRAM_TYPE_CONFORMITY = 7654;

  static BOARD_FINANCIAL_DECISION_TYPE_NO_FUNDING = 7005;

  static PAYMENT_STATUS_SCHEDULED_TERM_ID = 7381;
  static PAYMENT_STATUS_SCHEDULED_APPROVED_TO_BE_PAID_ID = 7382;
  static PAYMENT_STATUS_SCHEDULED_PAID_ID = 7383;

  static DOCUMENT_FINANCE_STRING = "finance";
  static DOCUMENT_AWF_STRING = "awf";

}


export class ECaseUtils {

  /*  METHOD USED TO SORT AN ARRAY BY SORTING KEY AND ALPHABETICALLY*/
  public static sortLovArrayAlphabeticallyAndBySortingKey(sortingArray, selectedLang): any [] {
    sortingArray = (sortingArray ? sortingArray : []).sort((a, b) => {
      let result = 0;
      try{
        if (a.sortingKey > b.sortingKey) {
          return 1;
        } else if (a.sortingKey < b.sortingKey) {
          return -1;
        } else {
          a.value[selectedLang] = (!a.value[selectedLang] || a.value[selectedLang] === null) ? '' : a.value[selectedLang];
          b.value[selectedLang] = (!b.value[selectedLang] || b.value[selectedLang] === null) ? '' : b.value[selectedLang];
          result = (a.value[selectedLang]).toLowerCase().localeCompare((b.value[selectedLang]).toLowerCase());
          return result;
        }
      }catch(e){
        console.error(e);
      }
      return result;
    });
    return sortingArray;
  }

  public static scrollToTop(selector?): void {
    selector = selector ? (selector) : '#eCase-main-content';
    const scrollBar = document.querySelector(selector);
    scrollBar.scrollTop = 0;
  }

  public static isUserHasAccessToUrl(url: string, tabs: any[]): boolean {
    tabs = tabs || [];
    return !!tabs.find(tab => (tab.url ? tab.url : '').includes(url) || this.isUserHasAccessToUrl(url, tab.children));
  }

  public static scrollToBottom(selector?, scrollPosition?: number): void {
    selector = selector ? selector : '#eCase-main-content';
    const scrollBar = document.querySelector(selector);
    scrollBar.scrollTop = (scrollPosition ? (scrollBar.scrollHeight / scrollPosition) : scrollBar.scrollHeight);
  }

  public static getBrowserName(): string {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!window.document['documentMode'] === true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  public static getBrowserLanguage(): string {
    let lang = '';
    if (navigator.language !== undefined && navigator.language !== '') {
      lang = navigator.language.includes('-') ?
        navigator.language.split('-')[0] :
        navigator.language;
    }
    return lang;
  }

  public static processDataSourceForTreeTable(sourceArray: any[], level): any [] {
    return sourceArray.map((row) => {
      row.children = row.children ? row.children : [];
      row.children = row.children.map((child) => {
        child.children = child.children ? child.children : [];
        child.isChild = true;
        return child;
      });
      row.level = level;
      row.children = this.processDataSourceForTreeTable(row.children, level + 1);
      row.isChildrenVisible = !!row.isChildrenVisible ? row.isChildrenVisible : false;
      return row;
    });
  }

  public static generateHyperLinkForDownload(response: HttpResponse<Blob>, fileName?: string): void {
    if (response.headers) {
      saveAs(response.body, fileName ? fileName : this.getFileNameFromResponseHeader(response));
    }
  }

  public static getFileNameFromResponseHeader(response: HttpResponse<Blob>): string {
    const header = response.headers.get('Content-Disposition');
    return header.match(/filename="(.+)"/)[1];
  }

  public static encodeStringToUTF8String(stringToBeFixed): string {
    let alteredString: string;
    if (!stringToBeFixed || stringToBeFixed === '') {
      return '';
    } else {
      alteredString = this.replaceAllWithoutRegex(stringToBeFixed, '\\u000A', '\u000A');
      alteredString = this.replaceAllWithoutRegex(alteredString, '\\u2022', '\u2022');
      return alteredString;
    }
  }

  public static initcap(stringToBeFixed: string): string {
    if (stringToBeFixed !== undefined) {
      return stringToBeFixed.toLowerCase().replace(/(?:^|\s|-|'|\.|:|,)[a-z\u00E0-\u00FC]/g, m => m.toUpperCase());
    } else {
      return stringToBeFixed;
    }

  }

  public static isValidJSONString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static setListKeysInSession(eCaseHttpService: EcaseHttpService, keyValue): Observable<any> {
    return eCaseHttpService.post('/api/setKeyValueListInSession', keyValue);
  }

  public static removeKeyListFromSession(eCaseHttpService: EcaseHttpService, keys): Observable<any> {
    return eCaseHttpService.post('/api/removeKeyListFromSession', keys);
  }

  public static removeCircularDependency(inputJson: any): any {
    const cache = new Set();
    return JSON.stringify(inputJson, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          // Circular reference found
          try {
            // If this value does not reference a parent it can be deduped
            return JSON.parse(JSON.stringify(value));
          } catch (err) {
            // discard key if value cannot be deduped
            return;
          }
        }
        // Store value in our set
        cache.add(value);
      }
      return value;
    });
  }


  public static textTruncate(text, length): string {
    return text ? ((text.length > length) ? (text.slice(0, length) + '...') : text) : '';
  }

  public static eCaseCloneDeep(obj): any {
    let copy;
    // Handle null or undefined
    if (null === obj || 'object' !== typeof obj) {
      return obj;
    }
    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    // Handle String
    if (obj instanceof String) {
      copy = obj + '';
      return copy;
    }
    // Handle Number
    if (obj instanceof Number) {
      copy = Number(obj + '');
      return copy;
    }
    // Handle Boolean
    if (obj instanceof Boolean) {
      copy = (obj + '') === 'true';
      return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.eCaseCloneDeep(obj[i]);
      }
      return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.eCaseCloneDeep(obj[attr]);
        }
      }
      return copy;
    }
  }

  public static getCleanedJson(json, isCleanError): any {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  public static flatten(array: any[]): any {
    return array.reduce((a, b) => {
      if (Array.isArray(b)) {
        return a.concat(this.flatten(b));
      }
      return a.concat(b);
    }, []);
  }

  public static isGlobalPrjPristine(newPrj, oldPrj): any {
    return this.getCleanedJson(JSON.stringify(newPrj), true) === this.getCleanedJson(JSON.stringify(oldPrj), true);
  }

  public static setKeyValueInTheSession(eCaseHttpService: EcaseHttpService, key: string, value: any, useWindowId?: boolean): Observable<any> {
    return eCaseHttpService.post('api/setKeyValueInTheSession ', {
      'key': key,
      'value': JSON.stringify(value),
      'useWindowId': useWindowId
    });
  }

  public static getKeyFromSession(eCaseHttpService: EcaseHttpService, key: string): Observable<any> {
    return eCaseHttpService.post('api/getKeyFromSession ', {'key': key});
  }

  public static filterArrayByString(mainArr, searchText): any[] {
    if (searchText === '') {
      return mainArr;
    }
    searchText = searchText.toLowerCase();
    return mainArr.filter(itemObj => this.searchInObj(itemObj, searchText));
  }

  public static replaceAllWithRegex(stringToBeReplaced, search, replacement): string {
    return stringToBeReplaced.replace(new RegExp(search, 'g'), replacement);
  };

  public static getCurrentTimeZone(): string {
    return moment.tz.zone(moment.tz.guess()).abbr(Date.now());
  }

  public static replaceAllWithoutRegex(stringToBeReplaced, search, replacement): string {
    return stringToBeReplaced.split(search).join(replacement);
  };

  public static searchInObj(itemObj, searchText): boolean {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public static searchInArray(arr, searchText): boolean {
    for (const value of arr) {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public static searchInString(value, searchText): boolean {
    return value.toLowerCase().includes(searchText);
  }

  public static generateGUID(): string {
    const S4 = (): string => Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return S4() + S4();
  }

  public static groupBy(list, keyGetter): Map<any, any> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public static multipleKeyGroupBy(array, f): any[] {
    const groups = {};
    array.forEach((o) => {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(group => groups[group]);
  }

  public static getTranslatedValueFromKey(translateService: TranslateService, key: string, languageID?: string): string {
    const currentLanguage: string = translateService.currentLang;
    if (languageID && languageID !== '') {
      translateService.currentLang = languageID;
    }
    let translatedValue = '';
    if (key && key !== '') {
      if (translateService.instant(key) === '') {
        for (const lang of translateService.langs) {
          translateService.currentLang = lang;
          if (translateService.instant(key) !== '') {
            translatedValue = translateService.instant(key);
            break;
          }
        }
      } else {
        translatedValue = translateService.instant(key);
      }
    }
    translateService.currentLang = currentLanguage;
    return translatedValue;
  }

  public static removeDuplicates(originalArray, property): any[] {
    const newArray = [];
    const lookupObject = {};
    for (const i in originalArray) {
      if (originalArray.hasOwnProperty(i) && originalArray[i]) {
        lookupObject[originalArray[i][property]] = originalArray[i];
      }
    }
    for (const i in lookupObject) {
      if (lookupObject[i]) {
        newArray.push(lookupObject[i]);
      }
    }
    return newArray;
  }

  public static removeDups(originalArray): any[] {
    const unique = {};
    originalArray.forEach((i) => {
      if (typeof i === 'string') {
        if (!unique[i]) {
          unique[i] = {
            value: true,
            type: 'string'
          };
        }
      } else if (typeof i === 'number' || typeof i === 'boolean') {
        if (!unique[i.toString()]) {
          unique[i.toString()] = {
            value: true,
            type: 'number'
          };
        }
      } else if (typeof i === 'boolean') {
        if (!unique[i.toString()]) {
          unique[i.toString()] = {
            value: true,
            type: 'boolean'
          };
        }
      } else {
        if (!unique[JSON.stringify(i)] || !unique[JSON.stringify(i)].value) {
          unique[JSON.stringify(i)] = {
            value: true,
            type: 'object'
          };
        }
      }
    });
    return Object.keys(unique).map(key => {
      switch (unique[key].type) {
        case 'string': return key;
        case 'number': return Number(key);
        case 'boolean': return key === 'true';
        case 'object': return JSON.parse(key);
      }
    });
  }

  public static handleize(text): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  public static sortLovAlphabeticallyWithoutSortingKey(lov: any, translateService: TranslateService): any {
    let totalOfSortingKeyValue = 0;
    lov.map((item) => {
      totalOfSortingKeyValue += item.sortingKey;
    });
    if (lov.length === totalOfSortingKeyValue) {
      lov.sort((a, b) => {
        const x = ((a.value[translateService.currentLang]) ? (a.value[translateService.currentLang]) : '').toLowerCase();
        const y = ((b.value[translateService.currentLang]) ? (b.value[translateService.currentLang]) : '').toLowerCase();
        return x.localeCompare(y);
      });
    }
    return lov;
  }

  public static getObjectChildFromPath(subject: any, path?: string): any {
    if (path !== undefined) {
      path.split('.').forEach((key) => {
        subject = subject[key];
      });
    }
    return subject;
  }

  public static sortColumn(event: any, dataSource: any[], translateService: TranslateService, childPath?: string): any[] {
    const scalarTypes = ['boolean', 'string', 'number'];
    let dataSorted = [];
    if (event) {
      const isAscending = event.direction === 'asc';
      const isDescending = event.direction === 'desc';
      const isUnsorted = event.direction === '';
      const defaultLang = translateService.getDefaultLang();
      switch (true) {
        case isAscending :
        case isDescending:
          dataSorted = dataSource.sort((a, b) => {
            a = this.getObjectChildFromPath(a, childPath);
            b = this.getObjectChildFromPath(b, childPath);
            let aV = a[event.active] ?? null;
            let bV = b[event.active] ?? null;
            if (aV === bV) {
              return 0;
            }
            if ((aV !== null && scalarTypes.includes(typeof aV)) || (bV !== null && scalarTypes.includes(typeof bV))) {
              if (aV === null && typeof bV === 'string') {
                aV = '';
              }
              if (bV === null && typeof aV === 'string') {
                bV = '';
              }
              if (event.isDate) {
                const aDate = new Date(aV);
                const bDate = new Date(bV);
                return this.compare(aDate, bDate, isAscending);
              } else {
                return (
                  typeof aV === 'string'
                    ? this.compare(
                      aV.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                      bV.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                      isAscending)
                    : this.compare(
                      aV,
                      bV,
                      isAscending)
                );
              }
            } else {
              if (!aV) {
                aV = {};
              }
              if (!bV) {
                bV = {};
              }
              if (!aV[defaultLang]) {
                aV[defaultLang] = '';
              }
              if (!bV[defaultLang]) {
                bV[defaultLang] = '';
              }
              return this.compare(
                aV[defaultLang].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                bV[defaultLang].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                isAscending
              );
            }
          });
          break;
        case isUnsorted:
          dataSorted = dataSource;
          break;
      }
    }
    return dataSorted;
  }

  public static fillEmptyDefaultLanguage(objText, translateService: TranslateService): any {
    if (!objText[translateService.getDefaultLang()] || objText[translateService.getDefaultLang()] === '') {
      let filled = false;
      translateService.getLangs().forEach((lang) => {
        if (!filled && objText[lang] && objText[lang] !== '') {
          objText[translateService.getDefaultLang()] = objText[lang];
          filled = true;
        }
      });
      if (!filled) {
        objText[translateService.getDefaultLang()] = '';
      }
    }
    return objText;
  }

  private static compare(a: number | string | Date, b: number | string | Date, isAsc: boolean): number {
    if(typeof a === 'string' && typeof b === 'string'){
      return a.localeCompare(b) * (isAsc ? 1 : -1);
    } else {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  public get18TextClob(eCaseHttpService: EcaseHttpService, code: string): Observable<any> {
    return eCaseHttpService.get('api/getClobTranslation/' + code);
  }

  public static normalizeString(str: string): string {
    return str
      .toLocaleLowerCase() // Convert to lowercase
      .normalize('NFD') // Normalize the string to decompose diacritics
      .replace(/[\u0300-\u036f]/g, ''); // Remove diacritic marks
  }

  public static includesNormalized(haystack: string, needle: string): boolean {
    return ECaseUtils.normalizeString(haystack).includes(ECaseUtils.normalizeString(needle));
  }
}

export class ECaseStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}